import React, { useState } from "react";
import "../styles/CreateImageAI.css";

// Import images directly
import AnimatedImage from "../assets/hats/animated.webp";
import BlackAndWhiteImage from "../assets/hats/black and white.webp";
import Cinematic from "../assets/hats/cinematic.webp"
import Creative from "../assets/hats/creative.webp"
import Minimalist from "../assets/hats/minimalist.webp"
import PopArt from "../assets/hats/pop art.webp"
import Portrait from "../assets/hats/Portrait.webp"
import PromotionalStyle from "../assets/hats/Promotional Style.webp"
import Realistic from "../assets/hats/Realistic.webp"
import Sketch from "../assets/hats/sketch.webp"
const CreateImageAI = ({ onResponse, onLoading }) => {
  const [formData, setFormData] = useState({
    description: "",
  });
  const [selectedStyle, setSelectedStyle] = useState("cinematic");


  // Predefined styles (imported objects)
  const imageStyles = [
    { name: "animated", src: AnimatedImage },
    { name: "black and white", src: BlackAndWhiteImage },
    {name: "cinematic", src: Cinematic},
    {name: "creative", src: Creative},
    {name: "minimalist", src: Minimalist},
    {name: "pop art", src: PopArt},
    {name: "portrait", src: Portrait},
    {name: "promotional style", src: PromotionalStyle},
    {name: "realistic", src: Realistic},
    {name: "sketch", src: Sketch}
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleStyleSelect = (styleName) => {
    setSelectedStyle(styleName);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.description) {
      console.error("Description is required.");
      return;
    }

    if (!selectedStyle) {
      console.error("Please select an image style.");
      return;
    }

    onLoading(true);

    try {
      // Append selected style to the description
      const updatedFormData = {
        ...formData,
        description: `${formData.description}\nImage style: ${selectedStyle}`,
      };

      await onResponse(updatedFormData);
    } catch (error) {
      console.error("Error submitting form:", error);
      onLoading(false); // Stop spinner on error
    }
  };

  return (
    <form onSubmit={handleSubmit} className="create-image-ai-form">
      <div>
        <label>Description</label>
        <textarea
          name="description"
          value={formData.description}
          onChange={handleChange}
          placeholder="Write your description here..."
          required
        />
      </div>
      <div className="image-style-container">
        <h3>Choose your style</h3>
        <div className="image-grid">
          {imageStyles.map((style) => (
            <div
              key={style.name}
              className={`style-option ${
                selectedStyle === style.name ? "selected" : ""
              }`}
              onClick={() => handleStyleSelect(style.name)}
            >
              <div className="image-wrapper">
                <img src={style.src} alt={style.name} />
              </div>
              <p>{style.name}</p>
            </div>
          ))}
        </div>
      </div>
      <button type="submit">Generate</button>
    </form>
  );
};

export default CreateImageAI;
