// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*body {*/
/*  font-family: Arial, sans-serif;*/
/*  margin: 0;*/
/*  padding: 0;*/
/*  background-color: #f8f9fa;*/
/*}*/
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,SAAS;AACT,oCAAoC;AACpC,eAAe;AACf,gBAAgB;AAChB,+BAA+B;AAC/B,IAAI","sourcesContent":["/*body {*/\n/*  font-family: Arial, sans-serif;*/\n/*  margin: 0;*/\n/*  padding: 0;*/\n/*  background-color: #f8f9fa;*/\n/*}*/\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
