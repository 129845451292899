import React, { useState } from 'react';
import '../styles/Sidebar.css'; // Add your CSS file
import logo from '../assets/logo.jpg'; // Adjust the path to your logo
import hamburgerIcon from '../assets/hamburger.png'; // Add your hamburger icon image
import closeIcon from '../assets/close.png'; // Add your close (X) icon image
import contentCreationIcon from '../assets/content_creation.png';
import LinkedIn from '../assets/Linkedin.svg'
import statisticsIcon from '../assets/statistics.png';
import chatIcon from '../assets/chat.png';
import adsIcon from '../assets/ads.png';
import Brainstorm from '../assets/Brainstorm.svg';
import Hook from '../assets/Hook.svg';
import Post from '../assets/Post.svg';
import DM from '../assets/DM.svg';
import socialIcon from '../assets/social.png';
import prIcon from '../assets/pr.png';
import emailIcon from '../assets/email.png'
import ecommerceIcon from '../assets/ecommerce.png'
import generalIcon from '../assets/general.png'
import refineIcon from '../assets/refine.png'
import contentBoosterIcon from '../assets/content_booster.png'
import translateIcon from '../assets/translation.png'
import CreateImageIcon from '../assets/image_create.png'
import CreateImageAiIcon from '../assets/image_ai.png'
import GoogleAnalyticsImage from '../assets/google-analytics.png'
import GoogleSearchImage from '../assets/google_search.png'
import CompetitorsImage from '../assets/competitor-analysis.png'
import TrendsImage from '../assets/google_trends.png'
import ChatAi from '../assets/chat_ai.png'
import SeoImage from '../assets/seo.png'
import KeyWordImage from '../assets/keyword.png'
import ComingSoon from '../assets/coming_soon.png'
import CompetitorsMain from "../assets/competitors_main.png";
import StudioImage from "../assets/studio.png";
import DomainImage from "../assets/domain.png"
import HomeImage from "../assets/home.png"
import DashboardImage from "../assets/dashboard.png";
import BookMarkImage from "../assets/bookmark.svg"
import RadarImage from "../assets/radar.svg"
import BrandVoice from "../assets/brand_voice.png"
import AiPersonalization from "../assets/ai_personalization.svg"
import BrandStrategy from "../assets/brand_strategy.png";
import TargetAudience from "../assets/target_audience.png";
import BrandProducts from "../assets/brand_products.png"
import BrandKnowledge from "../assets/brand_knowledge.png";
import TopPerforming from "../assets/top_performing.png";
import BrandBook from "../assets/brand_book.png"


const Sidebar = ({ setComponent }) => {
  const [isCollapsed, setIsCollapsed] = useState(true); // Sidebar collapsed state
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State to manage "Content Creation" dropdown
  // const [isAnalyticsDropdownOpen, setIsAnalyticsDropdownOpen] = useState(false);
  const [isChatDropdownOpen, setIsChatDropdownOpen] = useState(false);
  const [isSEODropdownOpen, setIsSEODropdownOpen] = useState(false);
  const [isBrandDropdownOpen, setIsBrandDropdownOpen] = useState(false);
  const [isCompetitorsDropdownOpen, setIsCompetitorsDropdownOpen] = useState(false);
  const [isStudioDropdownOpen, setIsStudioDropdownOpen] = useState(false);
  const [isStrategyDropdownOpen, setIsStrategyDropdownOpen] = useState(false);
  const [isDashboardDropdownOpen, setIsDashboardDropdownOpen] = useState(false);
  const [isFavoritesDropdownOpen, setIsFavoritesDropdownOpen] = useState(false);
  const [isRadarDropdownOpen, setIsRadarDropdownOpen] = useState(false);


  const handleToggleSidebar = () => {
    setIsCollapsed((prevState) => !prevState); // Toggle the collapsed state
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen); // Toggle "Content Creation" dropdown open/close
  };
  const toggleDashboardDropdown = () => {
    setIsDashboardDropdownOpen(!isDashboardDropdownOpen);
  }
  const toggleFavoritesDropdown = () => {
    setIsFavoritesDropdownOpen(!isFavoritesDropdownOpen);
  }
  const toggleRadarDropdown = () => {
    setIsRadarDropdownOpen(!isRadarDropdownOpen);
  }

  // const toggleAnalyticsDropdown = () => {
  //   setIsAnalyticsDropdownOpen(!isAnalyticsDropdownOpen); // Toggle "Statistics" dropdown open/close
  // };

  const toggleChatDropdown = () => {
    setIsChatDropdownOpen(!isChatDropdownOpen); // Toggle "AI chat" dropdown open/close
  };
  const toggleSEODropdown = () => {
    setIsSEODropdownOpen(!isSEODropdownOpen); // Toggle "AI chat" dropdown open/close
  };
  const toggleBrandDropdown = () => {
    setIsBrandDropdownOpen(!isBrandDropdownOpen); // Toggle "AI chat" dropdown open/close
  };
    const toggleCompetitorsDropdown = () => {
    setIsCompetitorsDropdownOpen(!isCompetitorsDropdownOpen); // Toggle "AI chat" dropdown open/close
  };
    const toggleStudioDropdown = () => {
    setIsStudioDropdownOpen(!isStudioDropdownOpen); // Toggle "AI chat" dropdown open/close
  };

    const toggleStrategyDropdown = () => {
    setIsStrategyDropdownOpen(!isStrategyDropdownOpen); // Toggle "AI chat" dropdown open/close
  };

  return (
      <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
        <div className="logo-container" onClick={handleToggleSidebar}>
          <img src={logo} alt="Logo" className="logo"/>
          {!isCollapsed && <span className="product-name">BrandWizard</span>}
        </div>

        <div className="sidebar-content">
          {!isCollapsed && (
              <>
                <div className="sidebar-title-with-icon" onClick={toggleRadarDropdown}>
                  <img src={RadarImage} alt="Radar" className="content-icon"/>
                  <span className="sidebar-title">Posts Radar</span>
                  <span className={isRadarDropdownOpen ? 'arrow-up' : 'arrow-down'}></span>
                </div>
                {isRadarDropdownOpen && (
                    <ul className="dropdown-menu">

                      <li onClick={() => setComponent('Radar')}>
                        <img src={RadarImage} alt="Favorites" className="dropdown-icon"/>
                        <span>Radar</span>
                      </li>
                    </ul>
                )}
                <div className="sidebar-title-with-icon" onClick={toggleDashboardDropdown}>
                  <img src={HomeImage} alt="Dashboard" className="content-icon"/>
                  <span className="sidebar-title">Dashboard</span>
                  <span className={isDashboardDropdownOpen ? 'arrow-up' : 'arrow-down'}></span>
                </div>
                {isDashboardDropdownOpen && (
                    <ul className="dropdown-menu">

                      <li onClick={() => setComponent('Dashboard')}>
                        <img src={DashboardImage} alt="Dashboard" className="dropdown-icon"/>
                        <span>Trends</span>
                      </li>
                    </ul>
                )}

                <div className="sidebar-title-with-icon" onClick={toggleDropdown}>
                  <img src={LinkedIn} alt="Content Creation Icon" className="content-icon"/>
                  <span className="sidebar-title">LinkedIn</span>
                  <span className={isDropdownOpen ? 'arrow-up' : 'arrow-down'}></span>
                </div>

                {isDropdownOpen && (
                    <ul className="dropdown-menu">
                      <li onClick={() => setComponent('CreateSocial')}>
                        <img src={Brainstorm} alt="Social Icon" className="dropdown-icon"/>
                        <span>Brainstorm</span>
                      </li>
                      <li onClick={() => setComponent('CreatePR')}>
                        <img src={Hook} alt="PR Icon" className="dropdown-icon"/>
                        <span>Hooks</span>
                      </li>
                      <li onClick={() => setComponent('CreateAd')}>
                        <img src={Post} alt="Ads Icon" className="dropdown-icon"/>
                        <span>Post</span>
                      </li>
                      <li onClick={() => setComponent('CreateEmail')}>
                        <img src={DM} alt="Email Icon" className="dropdown-icon"/>
                        <span>DM</span>
                      </li>
                    </ul>
                )}
                <div className="sidebar-title-with-icon" onClick={toggleFavoritesDropdown}>
                  <img src={BookMarkImage} alt="BookMark" className="content-icon"/>
                  <span className="sidebar-title">Favorites</span>
                  <span className={isFavoritesDropdownOpen ? 'arrow-up' : 'arrow-down'}></span>
                </div>
                {isFavoritesDropdownOpen && (
                    <ul className="dropdown-menu">

                      <li onClick={() => setComponent('Favorites')}>
                        <img src={BookMarkImage} alt="Favorites" className="dropdown-icon"/>
                        <span>Favorites</span>
                      </li>
                    </ul>
                )}

                {/*<div className="sidebar-title-with-icon" onClick={toggleAnalyticsDropdown}>*/}
                {/*  <img src={statisticsIcon} alt="Statistics Icon" className="content-icon"/>*/}
                {/*  <span className="sidebar-title">Data & Analytics</span>*/}
                {/*  <span className={isAnalyticsDropdownOpen ? 'arrow-up' : 'arrow-down'}></span>*/}
                {/*</div>*/}
                {/*{isAnalyticsDropdownOpen && (*/}
                {/*    <ul className="dropdown-menu">*/}
                {/*      <li onClick={() => setComponent('CreateAnalytics')}>*/}
                {/*        <img src={GoogleAnalyticsImage} alt="Google analytics" className="dropdown-icon"/>*/}
                {/*        <span>Google Analytics</span>*/}
                {/*      </li>*/}
                {/*      <li onClick={() => setComponent('CreateSearch')}>*/}
                {/*        <img src={GoogleSearchImage} alt="Google search" className="dropdown-icon"/>*/}
                {/*        <span>Google Search</span>*/}
                {/*      </li>*/}
                {/*      <li onClick={() => setComponent('CreateTrends')}>*/}
                {/*        <img src={TrendsImage} alt="Google Trends" className="dropdown-icon"/>*/}
                {/*        <span>Google Trends</span>*/}
                {/*      </li>*/}
                {/*    </ul>*/}
                {/*)}*/}

                {/*<div className="sidebar-title-with-icon" onClick={toggleChatDropdown}>*/}
                {/*  <img src={chatIcon} alt="AI Chat Icon" className="content-icon"/>*/}
                {/*  <span className="sidebar-title">AI Chat</span>*/}
                {/*  <span className={isChatDropdownOpen ? 'arrow-up' : 'arrow-down'}></span>*/}
                {/*</div>*/}
                {/*{isChatDropdownOpen && (*/}
                {/*    <ul className="dropdown-menu">*/}

                {/*      <li onClick={() => setComponent('Chat')}>*/}
                {/*        <img src={ChatAi} alt="Chat AI" className="dropdown-icon"/>*/}
                {/*        <span>AI Chat</span>*/}
                {/*      </li>*/}
                {/*    </ul>*/}
                {/*)}*/}

                {/*<div className="sidebar-title-with-icon" onClick={toggleSEODropdown}>*/}
                {/*  <img src={SeoImage} alt="AI Chat Icon" className="content-icon"/>*/}
                {/*  <span className="sidebar-title">SEO</span>*/}
                {/*  <span className={isSEODropdownOpen ? 'arrow-up' : 'arrow-down'}></span>*/}
                {/*</div>*/}
                {isSEODropdownOpen && (
                    <ul className="dropdown-menu">

                      <li onClick={() => setComponent('KeywordsIdeas')}>
                        <img src={KeyWordImage} alt="Keywords Ideas" className="dropdown-icon"/>
                        <span>Keywords Ideas</span>
                      </li>
                      <li onClick={() => setComponent('KeywordsSuggestions')}>
                        <img src={KeyWordImage} alt="Keywords Suggestions" className="dropdown-icon"/>
                        <span>Keywords Suggestions</span>
                      </li>
                      <li onClick={() => setComponent('CompetitorsKeywords')}>
                        <img src={KeyWordImage} alt="Competitors Keywords" className="dropdown-icon"/>
                        <span>Competitors Keywords</span>
                      </li>
                      <li>
                        <img src={ComingSoon} alt="Blog Wizard" className="dropdown-icon"/>
                        <span>Blog Wizard - (Coming soon) </span>
                      </li>
                      <li>
                        <img src={ComingSoon} alt="Website SEO" className="dropdown-icon"/>
                        <span>Website SEO - (Coming soon) </span>
                      </li>
                      <li>
                        <img src={ComingSoon} alt="Search Console" className="dropdown-icon"/>
                        <span>Search Console - (Coming soon) </span>
                      </li>
                    </ul>
                )}


                {/*<div className="sidebar-title-with-icon" onClick={toggleCompetitorsDropdown}>*/}
                {/*  <img src={CompetitorsMain} alt="Competitors main" className="content-icon"/>*/}
                {/*  <span className="sidebar-title">Competition</span>*/}
                {/*  <span className={isCompetitorsDropdownOpen ? 'arrow-up' : 'arrow-down'}></span>*/}
                {/*</div>*/}
                {isCompetitorsDropdownOpen && (
                    <ul className="dropdown-menu">
                      <li onClick={() => setComponent('CompetitorsAnalysis')}>
                        <img src={CompetitorsImage} alt="Competitors Analysis" className="dropdown-icon"/>
                        <span>Competitors Analysis</span>
                      </li>
                      <li onClick={() => setComponent('CompetitorsKeywords')}>
                        <img src={KeyWordImage} alt="Competitors Keywords" className="dropdown-icon"/>
                        <span>Competitors Keywords</span>
                      </li>
                      <li onClick={() => setComponent('CompetitorsDomain')}>
                        <img src={DomainImage} alt="Competitors Domain" className="dropdown-icon"/>
                        <span>Competitors domains</span>
                      </li>
                    </ul>
                )}
                <div className="sidebar-title-with-icon" onClick={toggleStudioDropdown}>
                  <img src={StudioImage} alt="Competitors main" className="content-icon"/>
                  <span className="sidebar-title">Creative studio</span>
                  <span className={isStudioDropdownOpen ? 'arrow-up' : 'arrow-down'}></span>
                </div>
                {isStudioDropdownOpen && (
                    <ul className="dropdown-menu">
                      <li onClick={() => setComponent('CreateImage')}>
                        <img src={CreateImageIcon} alt="Create img Icon" className="dropdown-icon"/>
                        <span>Stock Images</span>
                      </li>
                      <li onClick={() => setComponent('CreateImageAI')}>
                        <img src={CreateImageAiIcon} alt="Create img AI Icon" className="dropdown-icon"/>
                        <span>Create AI image</span>
                      </li>
                    </ul>
                )}
                <div className="sidebar-title-with-icon" onClick={toggleBrandDropdown}>
                  <img src={AiPersonalization} alt="Coming soon" className="content-icon"/>
                  <span className="sidebar-title">AI personalization</span>
                  <span className={isBrandDropdownOpen ? 'arrow-up' : 'arrow-down'}></span>
                </div>
                {isBrandDropdownOpen && (
                    <ul className="dropdown-menu">

                      <li onClick={() => setComponent('BrandVoice')}>
                        <img src={AiPersonalization} alt="Brand identity" className="dropdown-icon"/>
                        <span>Personalization</span>
                      </li>
                      {/*<li onClick={() => setComponent('BrandStrategy')}>*/}
                      {/*  <img src={BrandStrategy} alt="Brand strategy" className="dropdown-icon"/>*/}
                      {/*  <span>Brand strategy</span>*/}
                      {/*</li>*/}
                      {/*<li onClick={() => setComponent('TargetAudience')}>*/}
                      {/*  <img src={TargetAudience} alt="Target audience" className="dropdown-icon"/>*/}
                      {/*  <span>Target audience</span>*/}
                      {/*</li>*/}
                      {/*<li onClick={() => setComponent('ProductList')}>*/}
                      {/*  <img src={BrandProducts} alt="Brand products" className="dropdown-icon"/>*/}
                      {/*  <span>Brand Products</span>*/}
                      {/*</li>*/}
                      {/*<li onClick={() => setComponent('BrandKnowledge')}>*/}
                      {/*  <img src={BrandKnowledge} alt="Brand Knowledge" className="dropdown-icon"/>*/}
                      {/*  <span>Brand Knowledge</span>*/}
                      {/*</li>*/}
                      {/*<li onClick={() => setComponent('TopPerforming')}>*/}
                      {/*  <img src={TopPerforming} alt="Top Performing" className="dropdown-icon"/>*/}
                      {/*  <span>Top Performing</span>*/}
                      {/*</li>*/}
                    </ul>
                )}

                {/*<div className="sidebar-title-with-icon" onClick={toggleStrategyDropdown}>*/}
                {/*  <img src={ComingSoon} alt="Coming soon" className="content-icon"/>*/}
                {/*  <span className="sidebar-title">Strategy & Planning - (Coming soon)</span>*/}
                {/*  <span className={isStrategyDropdownOpen ? 'arrow-up' : 'arrow-down'}></span>*/}
                {/*</div>*/}
                {isStrategyDropdownOpen && (
                    <ul className="dropdown-menu">
                      <li>
                        <img src={ComingSoon} alt="Coming soon" className="dropdown-icon"/>
                        <span>Marketing strategy - (Coming soon) </span>
                      </li>
                      <li>
                        <img src={ComingSoon} alt="Coming soon" className="dropdown-icon"/>
                        <span>SWOT Analysis - (Coming soon) </span>
                      </li>
                      <li>
                        <img src={ComingSoon} alt="Coming soon" className="dropdown-icon"/>
                        <span>Gap Analysis - (Coming soon) </span>
                      </li>
                      <li>
                        <img src={ComingSoon} alt="Coming soon" className="dropdown-icon"/>
                        <span>Content Plan - (Coming soon) </span>
                      </li>

                    </ul>
                )}
              </>
          )}
        </div>
      </div>
  );
};

export default Sidebar;
