import React, { useState, useEffect, useCallback  } from "react";
import axios from "axios";
import "../styles/Signup.css";
import { API_URL } from "../config";
import {Link, useNavigate} from "react-router-dom";
import login_content from "../assets/login_content.png";
import ai_sparks from "../assets/ai_sparks.svg"
import ReactSlider from "react-slider";


function AdvancedPersonalization({ formData, handleChange, handleSubmit, handlePrevious }) {
  const handleWordLimitChange = (e) => {
  const inputText = e.target.value;
  const words = inputText.trim().split(/\s+/); // Split by spaces, handling multiple spaces
  if (words.length <= 50) { // Allow up to 50 words
    handleChange(e); // Update form data only if within limit
  }
};
  const handleWordLimitChangePost = (e) => {
  const inputText = e.target.value;
  const words = inputText.trim().split(/\s+/); // Split by spaces, handling multiple spaces
  if (words.length <= 200) { // Allow up to 50 words
    handleChange(e); // Update form data only if within limit
  }
};

  return (
    <div className="advanced-personalization-container">
      <h2>Grow on LinkedIn & Build a Personal Brand</h2>

      <div className="input-group-row">
        <div className="input-group">
          <label htmlFor="gender">Gender:</label>
          <select
            name="gender"
            className="signup-input"
            onChange={handleChange}
            value={formData.gender || ""}
          >
            <option value="">Choose your gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
            <option value="Prefer not to say">Prefer not to say</option>
          </select>
        </div>
        <div className="input-group">
          <label htmlFor="year_of_birth">Year of Birth:</label>
          <select
            name="year_of_birth"
            className="signup-input"
            onChange={handleChange}
            value={formData.year_of_birth || ""}
          >
            <option value="">Choose your year of birth</option>
            {Array.from({ length: 100 }, (_, i) => new Date().getFullYear() - i).map(
              (year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              )
            )}
          </select>
        </div>
      </div>

      {/*<div className="input-group-row">*/}
      {/*  /!*<div className="input-group">*!/*/}
      {/*  /!*  <label htmlFor="hobbies">Hobbies (up to 20 words):</label>*!/*/}
      {/*  /!*  <textarea*!/*/}
      {/*  /!*    name="hobbies"*!/*/}
      {/*  /!*    className="signup-input"*!/*/}
      {/*  /!*    placeholder="Enter your hobbies"*!/*/}
      {/*  /!*    onChange={handleChange}*!/*/}
      {/*  /!*    value={formData.hobbies || ""}*!/*/}
      {/*  /!*    maxLength={100}*!/*/}
      {/*  /!*  />*!/*/}
      {/*  /!*</div>*!/*/}
      {/*  <div className="input-group">*/}
      {/*    <label htmlFor="ambitions">Ambitions (up to 20 words):</label>*/}
      {/*    <textarea*/}
      {/*      name="ambitions"*/}
      {/*      className="signup-input"*/}
      {/*      placeholder="Enter your ambitions"*/}
      {/*      onChange={handleChange}*/}
      {/*      value={formData.ambitions || ""}*/}
      {/*      maxLength={100}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>*/}

      <div className="input-group-row">
        <div className="input-group">
          <label htmlFor="personality_description">
            Describe your personality (up to 50 words):
          </label>
          <textarea
              name="personality_description"
              className="signup-input"
              placeholder="Describe your personality"
              onChange={handleWordLimitChange}
              value={formData.personality_description || ""}
              style={{height: "150px"}}
          />
          <small>
            {formData.personality_description ? formData.personality_description.trim().split(/\s+/).length : 0}/50
            words
          </small>
        </div>
        <div className="input-group">
          <label htmlFor="tone_reflection">
            Share a sample post reflecting your tone (up to 200 words):
          </label>
          <textarea
              name="tone_reflection"
              className="signup-input"
              placeholder="Enter a text sample"
              onChange={handleWordLimitChangePost}
              value={formData.tone_reflection || ""}
              style={{height: "150px"}}
          />
          <small>
            {formData.tone_reflection ? formData.tone_reflection.trim().split(/\s+/).length : 0}/200
            words
          </small>
        </div>
      </div>
    </div>
  );
}


// Component for Personal Information
function LinkedInProfile({formData, setFormData, handleNext}) {
  const [linkedinUrl, setLinkedinUrl] = useState(formData.profile_url || ""); // Initialize with formData.profile_url
  const [loading, setLoading] = useState(false);

  const handleGeneratePersonalization = async () => {
    if (!linkedinUrl.trim()) {
      alert("Please enter a valid LinkedIn URL.");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        `${API_URL}/marketing_research/api/persona-onboarding-description/`,
        { profile_url: linkedinUrl }
      );

      const data = response.data;

      // Debugging: Log API response
      console.log("API Response Data:", data);

      // Update formData with LinkedIn URL and API response
      setFormData((prevFormData) => ({
        ...prevFormData,
        profile_url: linkedinUrl, // Save LinkedIn URL
        first_name: data.first_name || "",
        last_name: data.last_name || "",
        profile_description: data.profile_description || "",
        role: data.role || "",
        job_level: data.job_level || "",
        company_industry: data.company_industry || "",
        goal: data.goal || "",
        target_audience_description: data.target_audience_description || "",
        keywords: data.keywords || []
      }));

      alert("Personalization generated successfully!");
      handleNext(); // Proceed to the next step
    } catch (error) {
      console.error("Error generating personalization:", error);
      alert("Failed to generate personalization. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleUrlChange = (e) => {
    const url = e.target.value;
    setLinkedinUrl(url); // Update local state
    setFormData((prevFormData) => ({
      ...prevFormData,
      profile_url: url, // Update formData with LinkedIn URL
    }));
  };

  return (
      <div className="linkedin-profile-container">
        <h2>Let AI Create Your Persona</h2>
        <p>Provide your LinkedIn profile link to generate tailored insights.</p>
        <div className="input-group-row single">
          <label htmlFor="linkedin_url">LinkedIn Profile URL</label>
        </div>
        <div className="input-group-row single">
          <input
              type="text"
              name="linkedin_url"
              className="linkedin-input"
              placeholder="Enter your LinkedIn profile link"
              value={linkedinUrl}
              onChange={handleUrlChange} // Update state and formData on change
          />
        </div>
        <div className="button-container">
          <button
              className={`generate-button ${loading ? "disabled" : ""}`}
              onClick={handleGeneratePersonalization}
              disabled={loading}
          >
            <img src={ai_sparks} alt="AI Sparks" className="button-icon"/>
            {loading ? "Generating..." : "Generate Personalization"}
          </button>
        </div>

      </div>
  );
}


function LoginInfo({formData, handleChange, handleNext}) {
  const isNextButtonEnabled =
      formData.email &&
      formData.password &&
      formData.confirmPassword &&
      formData.password === formData.confirmPassword;

  const isEmailValid = formData.email.includes("@") && formData.email.includes(".");

  return (
      <div className="login-info-container">
        {/* Left Section */}
        <div className="login-image-container">
        <img src={login_content} alt="AI marketing illustration" />
        <h1 className="brand-text">Brand Wizard</h1>
        <p className="brand-description">
          Discover the magic of AI marketing, strategies & tools, crafted from
          your data for your audience.
        </p>
      </div>

      {/* Right Section */}
        <div className="login-form-container">
          <h2>Create your free account</h2>
          <p>
            You’re one step away from smarter, data-driven marketing with better results.
          </p>
          <div className="signup-link">
            <p>
              Already have an account? <Link to="/login">Login</Link>
            </p>
          </div>
          <div className="login-input-row">
            <label htmlFor="email">Email</label>
            <input
                type="email"
                name="email"
                id="email-input"
                className={`signup-input ${!isEmailValid && formData.email ? "invalid" : ""}`}
                placeholder="Enter your email"
                onChange={handleChange}
                value={formData.email}
                required
            />
            {!isEmailValid && formData.email && (
                <small className="error-message">Please enter a valid email address.</small>
            )}
          </div>
          <div className="login-input-row">
            <label htmlFor="password">Password</label>
            <input
                type="password"
                name="password"
                className="signup-input"
                placeholder="Type your password"
                onChange={handleChange}
                value={formData.password}
                required
            />
          </div>
          <div className="login-input-row">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
                type="password"
                name="confirmPassword"
                className="signup-input"
                placeholder="Confirm your password"
                onChange={handleChange}
                value={formData.confirmPassword}
                required
            />
            {formData.password && formData.confirmPassword && formData.password !== formData.confirmPassword && (
                <small className="error-message">Passwords do not match.</small>
            )}
          </div>
          <button id="start-signup-button"
                  onClick={handleNext}
                  disabled={!isNextButtonEnabled}
          >
            Submit & Unlock Marketing Magic
          </button>
        </div>
      </div>
  );
}


// PersonalInfo Component
function PersonalInfo({formData, handleChange, handleNext, handlePrevious}) {
  return (
      <div className="personal-info-container">
      {/* Header */}
      <div className="header-section">
        <h2>Grow on LinkedIn & Build a Personal Brand</h2>
      </div>

      {/* Form Fields */}
      <div className="input-group-row">
        <div className="input-group">
          <label htmlFor="first_name">First Name</label>
          <input
            type="text"
            name="first_name"
            className="signup-input"
            placeholder="Write your first name"
            onChange={handleChange}
            value={formData.first_name || ""}
            required
          />
        </div>
        <div className="input-group">
          <label htmlFor="last_name">Last Name</label>
          <input
            type="text"
            name="last_name"
            className="signup-input"
            placeholder="Write your last name"
            onChange={handleChange}
            value={formData.last_name || ""}
            required
          />
        </div>
      </div>

      <div className="input-group-row">
        <div className="input-group">
          <label htmlFor="role">Your Role</label>
          <select
            name="role"
            className="signup-input"
            onChange={handleChange}
            value={formData.role || ""}
          >
            <option value="">Choose your role</option>
            <option value="Marketing">Marketing</option>
            <option value="Design">Design</option>
            <option value="Engineering">Engineering</option>
            <option value="Finance">Finance</option>
            <option value="Product">Product</option>
            <option value="Sales">Sales</option>
            <option value="Customer Support">Customer Support</option>
            <option value="Customer Success">Customer Success</option>
            <option value="HR">HR</option>
            <option value="Project Management">Project Management</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <div className="input-group">
          <label htmlFor="job_level">Your Job Level</label>
          <select
            name="job_level"
            className="signup-input"
            onChange={handleChange}
            value={formData.job_level || ""}
          >
            <option value="">Choose your job level</option>
            <option value="C-Level">C-Level</option>
            <option value="VP or Executive">VP or Executive</option>
            <option value="Director">Director</option>
            <option value="Team Leader">Team Leader</option>
            <option value="Manager">Manager</option>
            <option value="Senior">Senior</option>
            <option value="Junior">Junior</option>
            <option value="Owner/Founder">Owner/Founder</option>
            <option value="Freelancer">Freelancer</option>
            <option value="Other">Other</option>
          </select>
        </div>
      </div>

      <div className="input-group-row">
        <div className="input-group">
          <label htmlFor="company_industry">Your Industry</label>
          <select
            name="company_industry"
            className="signup-input"
            onChange={handleChange}
            value={formData.company_industry || ""}
          >
            <option value="">Choose your industry</option>
            <option value="Technology">Technology</option>
            <option value="Finance">Finance</option>
            <option value="Healthcare">Healthcare</option>
            <option value="Retail">Retail</option>
            <option value="Education">Education</option>
            <option value="Manufacturing">Manufacturing</option>
            <option value="Real Estate">Real Estate</option>
            <option value="Transportation">Transportation</option>
            <option value="Energy">Energy</option>
            <option value="Entertainment">Entertainment</option>
            <option value="Hospitality">Hospitality</option>
            <option value="Construction">Construction</option>
            <option value="Legal">Legal</option>
            <option value="Agriculture">Agriculture</option>
            <option value="Telecommunications">Telecommunications</option>
            <option value="Automotive">Automotive</option>
            <option value="Food & Beverage">Food & Beverage</option>
            <option value="Aerospace">Aerospace</option>
            <option value="Public Sector">Public Sector</option>
          </select>
        </div>
        <div className="input-group">
          <label htmlFor="goal">Your Goal</label>
          <select
            name="goal"
            className="signup-input"
            onChange={handleChange}
            value={formData.goal || ""}
          >
            <option value="">Choose your goal</option>
            <option value="Build a personal brand">Build a personal brand</option>
            <option value="Generate leads">Generate leads</option>
            <option value="Promote my business">Promote my business</option>
            <option value="Networking">Networking</option>
            <option value="Find a job">Find a job</option>
          </select>
        </div>
      </div>
    </div>
  );
}



// Export without default
export { PersonalInfo };




// Component for Company Information
function CompanyInfo({ formData, setFormData, handleNext, handlePrevious }) {
  const [selectedTone, setSelectedTone] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState([]);
  const [painPoints, setPainPoints] = useState([]);
  const [Keywords, setKeywords] = useState([]);
  const [newPainPoint, setNewPainPoint] = useState(""); // To handle input for new pain points
  const [newKeyword, setNewKeyword] = useState(""); // To handle input for new pain points
  const toneOptions = [
    "Persuasive",
    "Analytical",
    "Friendly",
    "Thought-Provoking",
    "Conversational",
    "Thought Leadership",
    "Inspirational",
    "Professional",
    "Empathetic",
    "Casual",
    "Witty",
    "Educational",
  ];

  const IndustryTopics = [
      "Accommodation Services",
      "Administrative and Support Services",
      "Construction",
      "Consumer Services",
      "Education",
      "Entertainment Providers",
      "Farming || Ranching || Forestry",
      "Financial Services",
      "Government Administration",
      "Holding Companies",
      "Hospitals and Health Care",
      "Manufacturing",
      "Oil || Gas || Mining",
      "Professional Services",
      "Real Estate and Equipment Rental Services",
      "Retail",
      "Technology || Information || Media",
      "Transportation || Logistics || Supply Chain || Storage",
      "Utilities",
      "Wholesale"
  ];



  // Handle tone selection
const toggleTone = (tone) => {
  if (selectedTone.includes(tone)) {
    setSelectedTone(selectedTone.filter((item) => item !== tone));
  } else if (selectedTone.length < 2) {
    setSelectedTone([...selectedTone, tone]);
  }
};


const toggleTopic = (topic) => {
  if (selectedTopic.includes(topic)) {
    setSelectedTopic(selectedTopic.filter((item) => item !== topic));
  } else if (selectedTopic.length < 3) {
    setSelectedTopic([...selectedTopic, topic]);
  }
};

const handleWordLimitChange = (e) => {
  const inputText = e.target.value;
  const words = inputText.trim().split(/\s+/); // Split by spaces, handling multiple spaces
  if (words.length <= 50) { // Allow up to 50 words
    handleChange(e); // Update form data only if within limit
  }
};

  // Sync tones with formData
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      tone_of_voice: selectedTone.join(", "),
    }));
  }, [selectedTone, setFormData]);

    useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      industry_keywords: Keywords.join(", "),
    }));
  }, [Keywords, setFormData]);

      useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      industry_topics: selectedTopic.join(", "),
    }));
  }, [selectedTopic, setFormData]);

      useEffect(() => {
  if (formData.keywords && formData.keywords.length > 0) {
    setKeywords(formData.keywords);
  }
}, [formData.keywords]);



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const removePainPoint = (painPoint) => {
    setPainPoints((prev) => prev.filter((item) => item !== painPoint));
  };

  const removeKeyword = (Keyword) => {
    setKeywords((prev) => prev.filter((item) => item !== Keyword));
  };
  const addNewPainPoint = () => {
    if (newPainPoint.trim() !== "") {
      setPainPoints((prev) => [...prev, newPainPoint]);
      setNewPainPoint(""); // Reset input
    }
  };
  const addNewKeyword = () => {
    if (newKeyword.trim() !== "") {
      setKeywords((prev) => [...prev, newKeyword]);
      setNewKeyword(""); // Reset input
    }
  };

  return (
      <div className="company-info-container">
        <h2>Grow on LinkedIn & Build a Personal Brand</h2>
        <p>Professional personalization</p>

        <div className="input-group-row single">
          <label htmlFor="profile_description">
            Describe yourself professionally (up to 50 words):
          </label>
        </div>
        <small>{formData.profile_description ? formData.profile_description.trim().split(/\s+/).length : 0}/50
          words</small>
        <div className="input-group-row single">

          <textarea
              name="profile_description"
              className="textarea-input"
              placeholder="Write your description"
              onChange={handleWordLimitChange}
              value={formData.profile_description || ""}
          />
        </div>


        <div className="input-group-row single">
          <label htmlFor="target_audience_description">
            Describe your main target audience (up to 50 words):
          </label>
        </div>
        <small>
          {formData.target_audience_description ? formData.target_audience_description.trim().split(/\s+/).length : 0}/50 words
        </small>
        <div className="input-group-row single">
  <textarea
      name="target_audience_description"
      className="textarea-input"
      placeholder="Write your description"
      onChange={handleWordLimitChange} // Reusing function
      value={formData.target_audience_description || ""}
  />

        </div>
        <div>
          <h3>Industry Keywords</h3>

          <div className="input-group-row">
            <input
                type="text"
                className="signup-input"
                placeholder="Add new Keyword"
                value={newKeyword}
                onChange={(e) => setNewKeyword(e.target.value)}
            />
            <button className="add-button" onClick={addNewKeyword}>
              Add
            </button>
          </div>
          <div className="options-container">
            {Keywords.map((Keyword, index) => (
                <div key={index} className="option-item">
                  {Keyword}
                  <button
                      className="remove-button"
                      onClick={() => removeKeyword(Keyword)}
                  >
                    ✖
                  </button>
                </div>
            ))}
          </div>
        </div>

        <div className="tone-of-voice-container">
          <h3>Choose your industry topics (up to 3) to:</h3>
          <div className="tone-options">
            {IndustryTopics.map((option) => (
                <div
                    key={option}
                    className={`tone-option ${
                        selectedTopic.includes(option) ? "selected" : ""
                    }`}
                    onClick={() => toggleTopic(option)}
                >
                  {option}
                </div>
            ))}
          </div>
        </div>
        <div className="tone-of-voice-container">
          <h3>Choose your tone of voice (up to 2 characteristics):</h3>
          <div className="tone-options">
            {toneOptions.map((option) => (
                <div
                    key={option}
                    className={`tone-option ${
                        selectedTone.includes(option) ? "selected" : ""
                    }`}
                    onClick={() => toggleTone(option)}
                >
                  {option}
                </div>
            ))}
          </div>
        </div>

      </div>
  );
}


// Component for Brand Information
function BrandInfo({formData, handleChange, handleNext, handlePrevious}) {
  const [selectedPersonalities, setSelectedPersonalities] = useState([]);

  const personalityOptions = [
    "Innovative",
    "Trustworthy",
    "Friendly",
    "Professional",
    "Bold",
    "Sophisticated",
    "Authentic",
    "Fun",
    "Caring",
    "Inspirational",
    "Competent",
    "Leader",
    "Empowering",
  ];

  const togglePersonality = (personality) => {
    if (selectedPersonalities.includes(personality)) {
      // Remove personality if already selected
      setSelectedPersonalities((prev) =>
          prev.filter((item) => item !== personality)
      );
    } else if (selectedPersonalities.length < 3) {
      // Add personality if less than 3 selected
      setSelectedPersonalities((prev) => [...prev, personality]);
    }
  };

useEffect(() => {
  // Update formData whenever selectedPersonalities changes
  handleChange({
    target: {
      name: "brand_personality",
      value: selectedPersonalities.join(", "),
    },
  });
}, [selectedPersonalities, handleChange]); // Include handleChange in dependencies


  return (
    <div>
      <h2>Brand Info</h2>
        <div className="input-group">
            <div>
            <label htmlFor="brand_voice_name">Brand Voice Name</label>
            <input
                type="text"
                name="brand_voice_name"
                className="signup-input"
                placeholder="Define your brand’s unique voice"
                onChange={handleChange}
                value={formData.brand_voice_name}
            />
            </div>
        </div>
        <div className="personality-options-container">
        <h3>Select up to 3 Brand Personalities:</h3>
        <div className="personality-options">
          {personalityOptions.map((option) => (
            <div
              key={option}
              className={`personality-option ${
                selectedPersonalities.includes(option) ? "selected" : ""
              }`}
              onClick={() => togglePersonality(option)}
            >
              {option}
            </div>
          ))}
        </div>
      </div>
        <div className="input-group">
            <div>
            <label htmlFor="brand_mission">Brand Voice Name</label>
            <textarea
                name="brand_mission"
                className="signup-input"
                placeholder="Summarize your brand’s purpose and goals"
                onChange={handleChange}
                value={formData.brand_mission}
            />
            </div>
        </div>
        <div className="button-group">
            <button className="previous-button" onClick={handlePrevious}>
          Previous
        </button>
        <button className="next-button" onClick={handleNext}>
          Next
        </button>
      </div>
    </div>
  );
}

// Component for Target Audience

// Component for Target Audience
function TargetAudience({
  formData,
  setFormData,
  handleSubmit,
  handlePrevious,
}) {
  const [interests, setInterests] = useState([]);
  const [painPoints, setPainPoints] = useState([]);
  const [newInterest, setNewInterest] = useState(""); // To handle input for new interests
  const [newPainPoint, setNewPainPoint] = useState(""); // To handle input for new pain points
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchPersonaData = async () => {
      setLoading(true);
      try {
        if (!formData.company_url || formData.company_url.trim() === "") {
          throw new Error("Company URL is required");
        }

        const interestsResponse = await axios.post(
          `${API_URL}/marketing_research/api/persona-interest/`,
          { company_url: formData.company_url }
        );
        const interestsData = Array.isArray(interestsResponse.data.options)
          ? interestsResponse.data.options.map((item) => item.explanation)
          : [];
        setInterests(interestsData);

        const painPointsResponse = await axios.post(
          `${API_URL}/marketing_research/api/persona-pain/`,
          { company_url: formData.company_url }
        );
        const painPointsData = Array.isArray(painPointsResponse.data.options)
          ? painPointsResponse.data.options.map((item) => item.explanation)
          : [];
        setPainPoints(painPointsData);
      } catch (error) {
        console.error("Error fetching persona data:", error);
        alert("Failed to fetch persona data. Please ensure the company URL is valid.");
      } finally {
        setLoading(false);
      }
    };

    if (formData.company_url) {
      fetchPersonaData();
    }
  }, [formData.company_url]);

  const addNewInterest = () => {
    if (newInterest.trim() !== "") {
      setInterests((prev) => [...prev, newInterest]);
      setNewInterest(""); // Reset input
    }
  };

  const addNewPainPoint = () => {
    if (newPainPoint.trim() !== "") {
      setPainPoints((prev) => [...prev, newPainPoint]);
      setNewPainPoint(""); // Reset input
    }
  };

  const removeInterest = (interest) => {
    setInterests((prev) => prev.filter((item) => item !== interest));
  };

  const removePainPoint = (painPoint) => {
    setPainPoints((prev) => prev.filter((item) => item !== painPoint));
  };

  const handleFinalSubmit = () => {
    const updatedFormData = {
      ...formData,
      target_audience_interests: interests.join(", "),
      target_audience_pain_points: painPoints.join(", "),
    };

    setFormData(updatedFormData);
    handleSubmit(updatedFormData); // Pass updated data to submit
  };

  if (loading) {
    return (
      <div className="spinner-container">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div>
      <h2>Target Audience</h2>
        <div className="input-group-row">
            <div>
                <label htmlFor="target_audience_name">Audience Name</label>
                <input
                    type="text"
                    name="target_audience_name"
                    className="signup-input"
                    placeholder="Define your audience segment"
                    onChange={(e) =>
                        setFormData((prevFormData) => ({
                            ...prevFormData,
                            target_audience_name: e.target.value,
                        }))
                    }
                    value={formData.target_audience_name || ""}
                />
            </div>
            <div>
            <label htmlFor="target_audience_gender">Audience Name</label>
            <select
                name="target_audience_gender"
                className="signup-input"
                onChange={(e) =>
                    setFormData((prevFormData) => ({
                        ...prevFormData,
                        target_audience_gender: e.target.value,
                    }))
                }
                value={formData.target_audience_gender || ""}
            >
                <option value="">Choose gender preference</option>
                <option value="Female">Female</option>
                <option value="Male">Male</option>
                <option value="NotSpecified">Don't want to specify</option>
            </select>
            </div>
        </div>

        <div className="age-range-slider">
            <p>Target audience age?</p>
        <ReactSlider
          className="custom-slider"
          thumbClassName="custom-slider-thumb"
          trackClassName="custom-slider-track"
          value={[
            formData.target_audience_age_min || 10,
            formData.target_audience_age_max || 65,
          ]}
          min={10}
          max={65}
          step={1}
          onChange={(values) =>
            setFormData((prevFormData) => ({
              ...prevFormData,
              target_audience_age_min: values[0],
              target_audience_age_max: values[1],
            }))
          }
        />
        <div className="slider-labels">
          <span>Min: {formData.target_audience_age_min}</span>
          <span>Max: {formData.target_audience_age_max}</span>
        </div>
      </div>

      <div>
        <h3>Persona's Interests</h3>
        <div className="options-container">
          {interests.map((interest, index) => (
            <div key={index} className="option-item">
              {interest}
              <button
                className="remove-button"
                onClick={() => removeInterest(interest)}
              >
                ✖
              </button>
            </div>
          ))}
        </div>
        <div className="input-group-row">
          <input
            type="text"
            className="signup-input"
            placeholder="Add new interest"
            value={newInterest}
            onChange={(e) => setNewInterest(e.target.value)}
          />
          <button className="add-button" onClick={addNewInterest}>
            Add
          </button>
        </div>
      </div>

      <div>
        <h3>Persona's Pain Points</h3>
        <div className="options-container">
          {painPoints.map((painPoint, index) => (
            <div key={index} className="option-item">
              {painPoint}
              <button
                className="remove-button"
                onClick={() => removePainPoint(painPoint)}
              >
                ✖
              </button>
            </div>
          ))}
        </div>
        <div className="input-group-row">
          <input
            type="text"
            className="signup-input"
            placeholder="Add new pain point"
            value={newPainPoint}
            onChange={(e) => setNewPainPoint(e.target.value)}
          />
          <button className="add-button" onClick={addNewPainPoint}>
            Add
          </button>
        </div>
      </div>

      <div className="button-group">
        <button className="previous-button" onClick={handlePrevious}>
          Previous
        </button>
        <button className="submit-button" onClick={handleFinalSubmit}>
          Submit
        </button>
      </div>
    </div>
  );
}






function ProgressBar({step}) {
    const steps = ["Personal", "Company", "Brand", "Target audience"];
    return (
        <div className="progress-bar">
            {steps.map((label, index) => (
                <div
                    key={index}
                    className={`progress-step ${step === index + 1 ? "active" : ""}`}
                >
                    <span className="progress-index">{index + 1}</span>
                    <span className="progress-label">{label}</span>
                </div>
            ))}
        </div>
    );
}

// Main Signup Component

function Signup() {
  const [step, setStep] = useState(0); // Start with step 0 for LoginInfo
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    first_name: "",
    last_name: "",
    profile_url: "",
    job_level: "",
    role: "",
    company_name: "",
    company_url: "",
    company_industry: "",
    company_size: "",
    brand_voice_name: "",
    brand_personality: "",
    brand_mission: "",
    target_audience_name: "",
    target_audience_gender: "",
    target_audience_age_min: "",
    target_audience_age_max: "",
    goal: "",
    gender: "",
    year_of_birth: "",
    hobbies: "",
    ambitions: "",
    personality_description: "",
    tone_reflection: "",
  });

  const [csrfToken, setCsrfToken] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await axios.get(`${API_URL}/csrf-token/`);
        setCsrfToken(response.data.csrfToken);
      } catch (error) {
        console.error("Failed to fetch CSRF token:", error);
      }
    };
    fetchCsrfToken();
  }, []);

  const handleNext = () => setStep(step + 1);
  const handlePrevious = () => setStep(step - 1);

  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    },
    [setFormData]
  );

  const handleSubmit = async (finalFormData) => {
    if (finalFormData.password !== finalFormData.confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    try {
      const formDataFormatted = new FormData();
      Object.keys(finalFormData).forEach((key) => {
        if (finalFormData[key]) {
          formDataFormatted.append(key, finalFormData[key]);
        }
      });

      await axios.post(`${API_URL}/users/signup/`, formDataFormatted, {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRFToken": csrfToken,
        },
      });

      alert("Signup onboarding completed successfully!");
      navigate("/login");
    } catch (error) {
      alert("Signup onboarding failed. Please try again.");
    }
  };

  if (step === 0) {
    // Render LoginInfo outside of signup-container
    return (
      <LoginInfo
        formData={formData}
        handleChange={handleChange}
        handleNext={handleNext}
      />
    );
  }

return (
  <div className="signup-container">
    <div className="signup-header">
      <div className="signup-header-text">
        <h1>Let’s personalize the magic to your business and audience</h1>
        <p>Your info helps us deliver tailored insights and recommendations</p>
      </div>
      <img
        src={require("../assets/onboarding_content.png")}
        alt="Magic Wand"
        className="onboarding-image"
      />
    </div>

    {loading ? (
      <div className="spinner-container">
        <div className="spinner"></div>
      </div>
    ) : (
        <>
          <div className="signup-form">
            <div className="content-container">
              {step === 1 && (
                  <LinkedInProfile
                      formData={formData}
                      setFormData={setFormData}
                      handleNext={handleNext}
                  />
              )}
            </div>
            {step === 2 && (
                <div className="signup-form">
                  <div className="content-container">
                    <PersonalInfo
                        formData={formData}
                        handleChange={handleChange}
                        handleNext={handleNext}
                        handlePrevious={handlePrevious}
                    />
                  </div>
                </div>
            )}
            {step === 3 && (
                <CompanyInfo
                    formData={formData}
                    setFormData={setFormData}
                    handleNext={handleNext}
                    handlePrevious={handlePrevious}
                />
            )}
            {step === 4 && (
                <div className="signup-form">
                  <div className="content-container">
                    <AdvancedPersonalization
                        formData={formData}
                        handleChange={handleChange}
                        handlePrevious={handlePrevious}
                        handleSubmit={handleSubmit}
                    />
                  </div>
                </div>
            )}

            {/* Footer for Step Counter */}
            <div className="signup-form-footer">
              <p>Step {step} of 4</p>
              <div className="button-group">
                {step > 1 && (
                    <button className="previous-button" onClick={handlePrevious}>
                      Previous Step
                    </button>
                )}
                {step < 4 ? (
                    <button className="next-button" onClick={handleNext}>
                      Next Step
                    </button>
                ) : (
                    <button
                        className="finish-button"
                        onClick={() => handleSubmit(formData)} // Call handleSubmit on Finish
                    >
                      Finish
                    </button>
                )}
              </div>
            </div>

          </div>
        </>
    )}

  </div>
);

}

export default Signup;
